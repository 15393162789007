<template>
    <div class="video-modal-item">
        <a-modal class="video-modal" :visible="showAlerts" @cancel="handleCancelEvent" :footer="null">
            <!-- type == 3 密码观看 -->
            <template v-if="videoAuth.type == 3">
                <div class="flex x-left flex-wrap">
                    <div class="video-alert-title mt20 t-l">{{ videoAuth.msg }}</div>
                    <a-input class="mt20" size="large" placeholder="请输入密码" @change="handlePwInputEvent" />
                    <div class="video-submit-btn t-c colorfff mt20" @click="handleSubmitEvent">确认</div>
                </div>
            </template>
            <!-- type == 8 邀请码观看 -->
            <template v-if="videoAuth.type == 8">
                <div class="flex x-left flex-wrap">
                    <div class="video-alert-title mt20 t-l">{{ videoAuth.msg }}</div>
                    <a-input class="mt20" size="large" placeholder="请输入姓名" @change="handleInviteInputEvent($event,'real_name')" />
                    <a-input class="mt20" size="large" placeholder="请输入手机号" @change="handleInviteInputEvent($event,'mobile')" />
                    <a-input class="mt20" size="large" placeholder="请输入邀请码" @change="handleInviteInputEvent($event,'code')" />
                    <div class="video-submit-btn t-c colorfff mt20" @click="handleInviteSubmitEvent">确认</div>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
import storage from 'store'
import { message } from 'ant-design-vue'

export default {
    name: 'videoAuth',
    props: {
        showAlert: {
            type: Boolean,
            default: false,
        },
        videoAuth: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            pwValue: '', //密码
            showAlerts: false,
            inviteParams:{
                real_name:'',
                mobile:'',
                code:''
            }, //邀请码信息
        }
    },
    created() {
        // 模态框显示与否
        this.showAlerts = this.showAlert
    },
    methods: {
        // 关闭视频权限模态框
        handleCancelEvent() {
            this.showAlerts = false
            this.$parent.showAlert = false
        },
        // 视频权限模态框3密码输入
        handlePwInputEvent(e) {
            this.pwValue = e.target.value
        },
        // 视频权限3提交观看密码
        handleSubmitEvent() {
            let mm_code = this.pwValue
            let { id: type_id } = this.videoAuth
            let type = '4'
            if (!mm_code) {
                message.error("请输入验证信息");
                return;
            }
            if (!type_id) {
                message.error("请传入type_id : 视频id || 专题id || 直播id");
                return;
            }
            this.request.post('CheckMm', {
                type,
                type_id,
                mm_code: mm_code.replace(/(^\s*)|(\s*$)/g, '')
            }).then(res => {
                if (res.code == 200) {
                    message.success(res.msg)
                    storage.set(('is_mm_' + type + "_" + type_id), 1, 86400);
                    // 验证通过
                    // this.pageInit()
                    this.$parent.pageInit()
                    this.showAlerts = false
                    this.$parent.showAlert = false
                    this.pwValue = ''
                } else {
                    message.error(res.msg);
                }

            })
        },
        // 视频邀请码表单填写
        handleInviteInputEvent(e,type){
            this.inviteParams[type] = e.target.value
        },
        // 视频权限8提交邀请码
        handleInviteSubmitEvent(){
            if (!this.inviteParams['real_name']) {
                return message.error('姓名不能为空')
            }
            if (!this.inviteParams['mobile'] || !/^1[3-9]\d{9}$/.test(this.inviteParams['mobile'])) {
                return message.error('手机号格式不正确')
            }
            if (!this.inviteParams['code']) {
                return message.error('邀请码不能为空')
            }
            let { id: type_id } = this.videoAuth
            let type = '4'
            this.request.post('CheckInviteCode',{
                ...this.inviteParams,
                type,
                type_id
            }).then(res=>{
                if(res.code == 200){
                    message.success(res.msg)
                    storage.set(('is_invite_'+type+'_'+type_id), 1, 86400);
                    storage.set(('reg_id_'+type+'_'+type_id), res.reg_id, 86400);
                    this.$parent.pageInit()
                    this.showAlerts = false
                    this.$parent.showAlert = false
                }else{
                    message.error(res.msg);
                }
            })
        }
    },
    mounted() {

    },
}
</script>
<style lang="less" scoped>
// .video-modal-item {
.video-modal {
    /deep/.ant-modal-body {
        padding: 24px 24px 15px;
    }

    /deep/.ant-modal {
        // top: calc((100% - 350px)/2);
    }

    /deep/.ant-modal-footer {
        border-top: 0;
        padding: 10px 24px 20px;
    }

    .video-alert-title {
        font-size: 15px;
        width: 100%;
        line-height: 25px;
        color: #4b6f7b;
    }

    .video-submit-btn {
        width: 100%;
        display: block;
        font-size: 16px;
        height: 34px;
        line-height: 34px;
        background: #0099f3;
        border-radius: 5px;
        cursor: pointer;
    }

    .video-qrcode {
        margin: 15px auto;
    }

}

// }
</style>
