<template>
    <div class="wrapper video-wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="2" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left mobile-bread">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb class="flex x-left">
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/video-list' }">视频回放</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item style="max-width:960px;" class="row-1">{{videoDetailList.video_title}}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
            </div>
            <!-- 视频信息 -->
            <div class="video-play container flex">
                <div class="video-outer-box">
                    <video-play ref="videoPlayRef" @getCurrentTime="(value) => $refs?.transcriptListRef?.getCurrentTime(value)" v-if="videoPlayer.vid" :vid="videoPlayer.vid" :adMatter="playerAD" :bgImg="videoDetailList.default_pic_url | urlFilter(750)">
                    </video-play>
                    <!-- 权限提示 -->
                    <div v-else-if="videoAuth.id" class="no-vid-box" @click="handleVideoAuthModel">
                        <img class="no-vid-img" :src="videoDetailList.default_pic_url | urlFilter(750)" alt="">
                        <div class="video-auth-box flex-column x-center y-center">
                            <i class="med med-bofang"></i>
                            <div class="fs16 colorfff mt10">{{ videoAuth.msg }}</div>
                        </div>
                    </div>
                    <div v-else class="no-vid-box">
                        <img class="no-vid-img" :src="videoDetailList.default_pic_url | urlFilter(750)" alt="">
                        <div class="video-auth-box flex-column x-center y-center">
                            <i class="med med-bofang"></i>
                            <div class="fs16 colorfff mt10">{{ videoAuth.msg }}</div>
                        </div>
                    </div>
                    <!-- 播放器顶部广告（展示位置未确认先不展示） -->
                    <a-carousel v-if="topAD.length && false" class="carousel alert-tad-box" autoplay>
                        <img :src="item.img | urlFilter(750)" alt="" v-for="(item, index) in topAD[0]" :key="index" />
                    </a-carousel>
                    <!-- 播放器底部广告（展示位置未确认先不展示） -->
                    <a-carousel v-if="middleAD.length && false" class="carousel alert-middlead-box" autoplay>
                        <img :src="item.img | urlFilter(750)" alt="" v-for="(item, index) in middleAD[0]" :key="index" />
                    </a-carousel>
                    <!-- 弹框广告 -->
                    <div v-if="alertAD.length && !isNeedAttentionAccounts" class="alert-ad-box flex x-center y-center">
                        <img class="alert-ad-img" :src="alertAD[0].img | urlFilter(750) + '?id= ' + alertAD.length" />
                        <div class="close-btn-box flex x-center y-center" @click="handleCloseAlertAD">
                            <img class="close-btn-icon" src="~@/assets/images/close.png" />
                        </div>
                    </div>
                    <!-- 开屏广告 -->
                    <div v-if="welcomeAD.length && !isNeedAttentionAccounts" class="welcome-ad-box flex x-center y-center">
                        <img class="welcome-ad-img" :src="welcomeAD[0].img | urlFilter(750) + '?id= ' + welcomeAD.length" @load="handleWelcomeADLoad" @error="handleWelcomeADLoad" />
                        <div class="count-down-text colorfff" @click="handleCloseWelcomeAD">{{ welcomeADCountDown }}
                        </div>
                    </div>
                    <!-- 提示关注公众号 -->
                    <div class="alert-ad-box flex x-center y-center" v-if="isNeedAttentionAccounts">
                        <div class="popup-content flex-column x-center y-center">
                            <img src="https://files.sciconf.cn/mpweixin/officialAccounts.png!wx750" />
                            <div class="close-btn-box flex x-center y-center" @click="closePopup">
                                <img class="close-btn-icon" src="~@/assets/images/close.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="video-detail flex-column x-between">
                    <a-tabs style="width:100%;" default-active-key="1">
                        <a-tab-pane key="1" tab="讲者信息">
                            <!-- 个人信息 -->
                            <div class="video-content-name pl20 pr20 flex y-center">
                                <div class="flex y-center" style="cursor: pointer;" @click="expertJumpDetail(videoDetailList.user_id)">
                                    <img class="img" :src="videoDetailList.avatar | urlFilter(250)" alt="">
                                    <div class="flex-column ml15" style="max-width:180px;">
                                        <p class="t-l row-1">
                                            <span class="strong fs16 color333">{{ videoDetailList.real_name }}</span>
                                            <span class="ml10 color999 fs12">{{ videoDetailList.department }}</span>
                                        </p>
                                        <p class="t-l mt5 color666 fs12 row-1">{{ videoDetailList.org_cnname }}</p>
                                    </div>
                                </div>
                                <div class="attention-btn flex x-center y-center cursor" @click="getFollowExperts(videoDetailList.user_id,true)" v-if="videoDetailList.fans_status == 0">
                                    <i class="med med-jiahao"></i>
                                    <span class="fs12 strong colorfff ml5">关注</span>
                                </div>
                                <div class="isattention-btn flex x-center y-center cursor" @click="getFollowExperts(videoDetailList.user_id)" v-if="videoDetailList.fans_status == 1">
                                    <span class="fs12 strong color999 ml5">已关注</span>
                                </div>
                            </div>
                            <!-- 粉丝数等 -->
                            <div style="height:80px;" class="video-content-name flex x-around pl10 pr10 y-center">
                                <div class="flex-column">
                                    <span class="strong color333 fs16">{{ userDetail.video_num || 0 }}</span>
                                    <span class="fs12 color333">视频</span>
                                </div>
                                <div class="line"></div>
                                <div class="flex-column">
                                    <span class="strong color333 fs16">{{ userDetail.abstract_num || 0 }}</span>
                                    <span class="fs12 color333">论文</span>
                                </div>
                                <div class="line"></div>
                                <div class="flex-column">
                                    <span class="strong color333 fs16">{{ userDetail.fans_num || 0 }}</span>
                                    <span class="fs12 color333">粉丝</span>
                                </div>
                            </div>
                            <!-- 个人介绍 -->
                            <div v-if="userDetail.resume" class="video-content-name content-desc t-l">
                                <div class="pt10 pb20 pl20 pr20" ref="resumeContent" v-html="userDetail.resume"></div>
                            </div>
                            <div v-else class="video-content-name content-desc pt20 pb20 pl20 pr20 t-l color999">暂无介绍
                            </div>
                            <!-- 关键词 -->
                            <div class="content-subject flex x-left y-center">
                                <template v-if="videoDetailList.keyword">
                                    <span class="content-subject-item colorfff fs12 t-l" :class="{ 'ml10': index != 0 }" v-for="(item, index) in strToArr(videoDetailList.keyword)" :key="index">{{ item
                                        }}</span>
                                </template>
                            </div>
                            <!-- 浏览量等 -->
                            <div class="page-view flex y-center">
                                <div>
                                    <i class="med med-yanjing"></i>
                                    <span class="fs12 color999 ml5">{{ videoDetailList.hits }}人</span>
                                </div>
                                <div class="color999 fs12">
                                    <span class="cursor" @click="getVideoLikeCollect('2')">
                                        <i :class="['med', videoDetailList.collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                                        <span class="ml5">{{ videoDetailList.collect == 1 ? '已收藏' : '收藏' }}</span>
                                    </span>
                                    <span class="ml15 cursor" @click="getVideoLikeCollect('1')">
                                        <i :class="['med', videoDetailList.zan == 1 ? 'med-dianzan' : 'med-dianzan_huaban']"></i>
                                        <span class="ml5">{{ videoDetailList.zan == 1 ? '已赞' : '点赞' }}</span>
                                    </span>
                                    <!-- <span class="ml15 cursor">
                                        <img src="~@/assets/images/transpond.png" alt="">
                                        <span class="ml5">转发</span>
                                    </span> -->
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="文字稿">
                            <transcript-list ref="transcriptListRef" @seekVideo="(value) => $refs.videoPlayRef.seekVideo(value)"></transcript-list>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <!-- 相关视频 -->
            <div class="container flex about-video" style="padding:20px 20px 0 20px;">
                <div class="video-other" :class="{ 'video-other-left': activeKey == 4 }">
                    <a-tabs v-model:activeKey="activeKey" :animated="false" @change="handleTabChangeEvent">
                        <a-tab-pane key="1" tab="相关视频" class="mt10">
                            <a-spin :spinning="loading" tip="Loading...">
                                <div class="container" style="min-height:200px;">
                                    <course-item width="216px" height="124px" :lineCount="5" :data-list="videoPlaybackList" type="2" :targetType="1" @clickEvent="handleOtherVideoEvent"></course-item>
                                    <a-empty v-if="!videoPlaybackList.length && !loading" />
                                </div>
                            </a-spin>
                            <div class="t-c" v-if="relatedTotal > 0">
                                <a-pagination :total="relatedTotal" v-model:current="page" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="作者相关" force-render>
                            <a-spin :spinning="loading" tip="Loading...">
                                <div class="container" style="min-height:200px;">
                                    <div v-if="authorMeetingList && authorMeetingList.length > 0">
                                        <!-- <img class="img mb15" src="~@/assets/images/video-play-meeting.png" alt=""> -->
                                        <course-item width="216px" :lineCount="5" height="124px" :data-list="authorMeetingList" :targetType="1" @clickEvent="handleOtherVideoEvent"></course-item>
                                        <div class="t-c" v-if="authorMeetCount > 0">
                                            <a-pagination :total="authorMeetCount" v-model:current="meetPage" show-quick-jumper :defaultPageSize="10" @change="handleMeetChangeEvent" :hideOnSinglePage="true" />
                                        </div>
                                    </div>
                                    <div v-if="!authorMeetingList.length &&authorPaperList && authorPaperList.length > 0">
                                        <div v-for="(item, index) in authorPaperList" :key="index" class="t-l pb20 pt20 dis-block" :class="{ 'bb': index != authorPaperList.length - 1 }" @click="handlePaperDetailEvent(item.id)">
                                            <span class="dis-block strong color333 fs16 row-1">{{ item.title }}</span>
                                            <span class="dis-block color333 fs12 mt10 row-2" v-if="item.abstract"><span class="strong">摘要:
                                                </span>{{ item.abstract }}</span>
                                            <span class="dis-block color333 fs12 mt5 row-1" v-if="item.keywords"><span class="strong">关键词:
                                                </span>{{ item.keywords }}</span>
                                            <span class="dis-block color333 fs12 mt5 row-1" v-if="item.author"><span class="strong">作者:
                                                </span>{{ item.author }}</span>
                                        </div>
                                        <div class="t-c" v-if="authorPaperCount > 0">
                                            <a-pagination :total="authorPaperCount" v-model:current="paperPage" show-quick-jumper :defaultPageSize="5" @change="handlePaperChangeEvent" :hideOnSinglePage="true" />
                                        </div>
                                    </div>
                                    <a-empty v-if="!authorMeetingList.length && !authorPaperList.length && !loading" />
                                </div>
                            </a-spin>

                        </a-tab-pane>
                        <a-tab-pane key="3" tab="相关指南">
                            <a-spin :spinning="loading" tip="Loading...">
                                <template v-if="guideList && guideList.length > 0">
                                    <div class="list-items">
                                        <text-item path="/guide-list-detail" type="3" gap="5" v-for="(item, index) in guideList" :key="index" :item="item"></text-item>
                                    </div>
                                    <div class="t-c mt30" v-if="guideCount > 0">
                                        <a-pagination :total="guideCount" v-model:current="guideParams.page" :page-size="guideParams.pageSize" :show-size-changer="false" show-quick-jumper @change="guidePageChangeEvent" :hideOnSinglePage="true" />
                                    </div>
                                </template>
                                <a-empty v-if="!guideList.length && !loading" />
                            </a-spin>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="视频评论">
                            <a-spin :spinning="loading" tip="Loading...">
                                <div class="" style="min-height:200px;">
                                    <comment-list :list="commentList" :commentCount="commentCount" :pageSize="commentParams.limit" @pageEvent="commentPageChangeEvent($event)" @handleTextChange="handleTextChange($event)"></comment-list>
                                </div>
                            </a-spin>
                        </a-tab-pane>
                        
                    </a-tabs>
                </div>
                <div v-if="activeKey == 4" class="about-meeting" style="width:33.33%;">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
            <!-- 视频权限模态框 -->
            <video-auth-modal v-if="showAlert" :showAlert="showAlert" :videoAuth="videoAuth"></video-auth-modal>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import layoutRight from '@/components/layout/layout-right';
import videoPlay from '@/components/video-player/video-play.vue';
import courseItem from '@/components/courser-list-item';
import commentList from '@/components/comment-list.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import textItem from '@/components/text-item.vue';
import videoAuthModal from '@/components/video-auth-modal.vue'
import { videoLogo, mediaNavList } from '@/config/const';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { expertJumpDetail, videoAuthJump } from '@/utils/jumpPageMethods';
import { isArray, isObject } from '@/utils/judgeDataType.js';
import { hightLight } from '@/utils/common';
import storage from 'store'
import { mini_url, committee_id } from '@/config'
import { mapState } from 'vuex';
import transcriptList from '@/components/transcript-list.vue'
export default {
    name: "videoListDetail",
    components: {
        transcriptList,
        commonTitle,
        Header,
        Footer,
        layoutRight,
        videoPlay,
        courseItem,
        commentList,
        acrossMediaItem,
        textItem,
        videoAuthModal
    },
    data() {
        return {
            mini_url,
            committee_id,
            videoId: this.$route.query.id,
            loading: true,
            activeKey: '1',
            subjects: '',
            logoUrl: videoLogo,
            navList: mediaNavList,//顶部菜单
            videoDetailList: {}, //视频详情
            userDetail: {}, //部分个人信息
            page: 1,
            relatedTotal: 0,
            videoPlaybackList: [], //相关视频
            authorMeetingList: [], //相关会议
            authorMeetCount: 0, //相关会议视频总数
            meetPage: 1,
            authorPaperCount: 0,
            paperPage: 1,
            authorPaperList: [], //相关论文
            guideCount: 0,
            guideList: [], //相关指南列表
            guideParams: {
                page: 1,
                pageSize: 5,
                subject_ids: '',
                keyword: '',
                // rm_guide_id: '26735',
            },
            commentList: [], //视频评论
            commentCount: 0, //评论总数
            commentParams: {
                page: 1,
                limit: 5,
                video_id: '',
            }, //视频评论列表参数
            // 视频权限
            videoAuth: {
                id: 0,
                type: 0,
                msg: '',
                code_url: '',
                showAlert: false
            },
            // 视频播放器
            videoPlayer: {
                log_id: '',
                sign: '',
                ts_video: '',
                vid: '',
                video_id: ''
            },
            showAlert: false, //是否显示观看视频模态框
            commentContent: '',// 开屏广告
            welcomeAD: [],// 弹窗广告
            alertAD: [],// 弹窗广告
            topAD: [], //播放器顶部广告
            middleAD: [], //播放器底部广告
            playerAD: [], //播放器广告
            welcomeADCountDown: '5秒后关闭', // 欢迎广告倒计时
            isNeedAttentionAccounts: false,//是否需要关注公众号
            notFirstRun: false, // 记录非首次运行
            layoutRightConfig: {
                correlationMeet: { //相关会议
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        subject_ids: ''
                    },   // 参数条件
                },
            },
            currentActive: 0,
            searchVal: '', // 搜索内容
            isStopRoll: false // 是否停止自动滚动
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        expertJumpDetail,
        videoAuthJump,
        getVideoInfo() {
            return this.request.get('GetVideoInfo', { video_id: this.videoId })
        },
        getRelatedVideo() {
            this.request.get('RelatedVideo', { video_id: this.videoId }).then(res => {
                let videoObj = {
                    img: "default_pic_url",
                }
                this.relatedTotal = res.data.length
                if (res.data.length) {
                    let data = res.data.slice(this.page * 10 - 10, 10)
                    this.videoPlaybackList = jsonFormat(data, videoObj)
                } else {
                    this.videoPlaybackList = []
                }

            })
        },
        // 专家相关会议
        getAuthorMeetList() {
            this.loading = true
            this.request.get('GetUserVideoInfo', { page: this.meetPage, limit: 10, video_id: this.videoId }).then(res => {
                this.authorMeetCount = Number(res.data.count) || 0
                if (res.data.list.length) {
                    let meetObj = {
                        img: "default_pic_url",
                        author: 'real_name'
                    }
                    this.authorMeetingList = jsonFormat(res.data.list, meetObj)
                } else {
                    this.authorMeetingList = []
                    this.getAuthorPaperList()
                }
                this.loading = false
            })
        },
        
        // 相关会议视频切换页码
        handleMeetChangeEvent(page) {
            this.meetPage = page
            this.getAuthorMeetList()
        },
        // 相关会议论文切换页码
        handlePaperChangeEvent(page) {
            this.paperPage = page
            this.getAuthorPaperList()
        },
        handlePaperDetailEvent(id) {
            this.$router.push({
                path: '/paper-detail',
                query: {
                    id: id,
                }
            })
        },
        // 专家相关论文
        getAuthorPaperList() {
            if (this.videoDetailList.user_id != 0) {
                this.loading = true
                this.request.post('ExpertsPaperList', { experts_uid: this.videoDetailList.user_id }).then(res => {
                    this.authorPaperCount = res.data.length
                    if (res.data.length) {
                        this.authorPaperList = res.data.slice(this.paperPage * 5 - 5, this.paperPage * 5)
                    } else {
                        this.authorPaperList = []
                    }
                    this.loading = false
                })
            }

        },
        // 视频评论
        getCommentList() {
            this.loading = true
            this.commentParams.video_id = this.videoId
            this.layoutRightConfig.correlationMeet.data.subject_ids = this.subjects
            this.request.get('GetVideoComment', this.commentParams).then(res => {
                this.commentCount = parseInt(res.data.count)
                this.commentList = res.data.list
                this.loading = false
            })
        },
        handleOtherVideoEvent(item) {
            this.videoPlayer.vid = ''
            this.videoAuth.id = ''
            this.videoId = item.id
            this.page = 1

            this.pageInit();
            this.getRelatedVideo();
            this.$router.replace({
                path: '/video-list-detail?id=' + item.id
            })

        },
        // 相关指南
        getGuideList() {
            this.loading = true
            this.guideParams.subject_ids = this.subjects
            this.guideParams.keyword = this.videoDetailList.keyword
            this.request.get('RelatedGuides', this.guideParams).then(res => {
                let obj = {
                    id: 'guide_id',
                    news_title: 'title_cn',
                    create_time: 'publish_date',
                    source: 'reference',
                }
                this.guideCount = parseInt(res.data.count)
                this.guideList = jsonFormat(res.data.list, obj)
                this.loading = false
            })
        },
        //关注
        getFollowExperts(userid,is_focus) {
            this.request.post('FollowExperts', { experts_uid: userid }).then(res => {
                if (res.data.code == 200) {
                    this.videoDetailList.fans_status = this.videoDetailList.fans_status == 0 ? 1 : 0
                }
                //关注医生添加积分,只有关注的时候才加积分
                if (is_focus){
                    this.request.post('AddPoint', { task_id: 3, type_id: userid },{ hideModal: true }).then(res => {}).catch(err => {})
                }

            })
        },
        // 收藏/点赞
        getVideoLikeCollect(type) {
            this.request.get('GetVideoLikeCollect', {
                id: this.videoId,
                type: type
            }).then(res => {
                if (res.data == 1) {
                    if (type == 1) {
                        this.videoDetailList.zan = 2
                    } else {
                        this.videoDetailList.collect = 2
                    }
                } else {
                    if (type == 1) {
                        this.videoDetailList.zan = 1;
                        // 增加积分
                        this.request.post('AddPoint', { task_id: 8, type_id: this.videoId },{ hideModal: true }).then(res => {}).catch(err => {})
                    } else {
                        this.videoDetailList.collect = 1
                    }
                }
            })
        },
        // 相关视频切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.page = page
            this.request.get('RelatedVideo', { video_id: this.videoId }).then(res => {
                let videoObj = {
                    img: "default_pic_url",
                }
                let data = res.data.slice(this.page * 10 - 10, this.page * 10)
                this.videoPlaybackList = jsonFormat(data, videoObj)
            })
        },
        // 选项卡切换
        handleTabChangeEvent(key) {
            if (key == 2) {
                if (this.authorMeetingList.length == 0) {
                    this.getAuthorMeetList()
                }
                // if (this.authorPaperList.length == 0) {
                //     this.getAuthorPaperList()
                // }
            }
            if (key == 4 && this.commentList.length == 0) {
                this.getCommentList()
            }
            if (key == 3 && this.guideList.length == 0) {
                this.getGuideList()
            }
        },
        // 视频评论切换页码
        commentPageChangeEvent(e) {
            this.commentParams.page = e
            this.getCommentList()
        },
        // 发表评论
        handleTextChange(e) {
            let commentValue = e;
            this.request.get('AddCommentVideo', { content: commentValue, vid: this.videoId }).then(res => {
                this.commentParams.page = 1;
                // 增加积分
                this.request.post('AddPoint', { task_id: 8, type_id: this.videoId },{ hideModal: true }).then(res => {}).catch(err => {});
                this.getCommentList()
            })
        },
        // 相关指南列表切换页码
        guidePageChangeEvent(e) {
            this.guideParams.page = e
            this.getGuideList()
        },
        // 获取与设置权限
        getVideoAuth() {
            let config = {
                open_id: '',
                video_id: this.videoId
            }
            let is_mm = storage.get('is_mm_4_' + this.videoId);
            is_mm && (config['is_mm'] = is_mm) // 是否通过密码验证 0 未通过 1通过
            let is_reg = storage.get('is_reg_4_' + this.videoId);
            is_reg && (config['is_reg'] = is_reg) // 是否登记过 0 未登记 1 登记
            let is_invite = storage.get('is_invite_4_' + this.videoId);
            is_invite && (config['is_invite'] = is_invite) // 是否通过邀请码验证 0未通过 1通过
            let reg_id = storage.get('reg_id_4_' + this.videoId);
            reg_id && (config['reg_id'] = reg_id) // 登记信息id
            return config;
        },
        //视频播放器相关参数、视频权限等
        getVideoParams() {
            //, device_type: '1' 
            return this.request.get('newGetVideoParams', { ...this.getVideoAuth()})
        },
        //判断用户观看免费视频是否弹出关注服务号二维码
        getIsOpenQrcode() {
            return this.request.get('IsOpenQrcode', { video_id: this.videoId })
        },
        // 视频广告
        getVideoAd() {
            return this.request.get('GetVideoAd', { video_id: this.videoId })
        },
        //获取当天日期
        getTodayDate() {
            let myDate = new Date();//当前时间戳
            let strDate = myDate.toLocaleDateString();
            return strDate;
        },
        pageInit() {
            let requestArr = [
                this.getVideoParams(), //视频播放器相关参数
                this.getVideoInfo(), //视频信息
                this.getIsOpenQrcode(),
            ]
            // 首次加载广告
            if (!this.notFirstRun) {
                let newRequestArr = [
                    this.getVideoAd(),
                ]
                requestArr = requestArr.concat(newRequestArr)
            }
            Promise.all(requestArr).then(res => {
                // 获取视频权限
                let videoAuth = res[0] || {}
                let { type } = videoAuth
                // 有权限 返回权限相关
                if (type) {
                    let msg = videoAuth.msg;
                    this.videoAuth = {
                        id: this.videoId,
                        type: type,
                        msg,
                        code_url: videoAuth.code_url,
                        showAlert: false
                    }
                    if (!(this.userInfo || {}).user_id) {
                        if (this.videoAuth.type != 0 && this.videoAuth.type != 3 && this.videoAuth.type != 8) {
                            this.videoAuth.msg = '请登录后观看'
                            // window.location.href = this.mini_url + "unified/login?return_url="+encodeURIComponent(window.location.href);
                            // return false
                        }
                    }
                    // 无权限 返回视频信息
                } else if (videoAuth.data) {
                    let { data } = videoAuth
                    this.videoPlayer = {
                        log_id: data.log_id,
                        sign: data.sign,
                        ts_video: data.ts_video,
                        vid: data.vid,
                        video_id: data.video_id
                    }
                    this.videoAuth.msg = videoAuth?.msg || ''
                } else {
                    // console.log('播放器异常')
                }
                // 视频信息
                let videoDetailList = res[1].data.video
                let uid = videoDetailList.user_id
                videoDetailList.subject.forEach(item => {
                    this.subjects += `${item.id},`
                })
                this.videoDetailList = videoDetailList
                // 个人信息
                if (uid != 0 && (this.userInfo || {}).user_id) {
                    setTimeout(() => {
                        this.request.post('ExpertsInfoNew', { experts_uid: uid }).then(res => {
                            let resume = res.data.resume || ''
                            if (resume && resume.indexOf("<img ") != -1) {
                                res.data.resume = resume.replace('<img ', '<img style="width:100%;" ')
                            }
                            this.userDetail = res.data
                        })
                    }, 1000)

                }

                // 当前日期
                let todayDate = this.getTodayDate();
                // 是否显示关注公众号弹窗
                if (storage.get('videoQrCode') !== todayDate && res[2].is_open) {
                    this.isNeedAttentionAccounts = true || res[2].is_open;
                    storage.set('videoQrCode', todayDate);
                }
                if (!this.notFirstRun) {
                    this.notFirstRun = true;
                    // 广告相关数据
                    let { meeting_ad = {}, video_ad = {}, video_player_ad = {} } = res[3].data || {}
                    // 开屏广告  
                    let welcomeAD = [],
                        alertAD = [],
                        topAD = [],
                        middleAD = [],
                        playerAD = [];
                    // 会议广告
                    if (isObject(meeting_ad)) {
                        let {
                            welcome: _welcomeAD,
                            alert: _alertAD,
                            top: _topAD,
                            middle: _middleAD
                        } = meeting_ad
                        // 开屏广告
                        if (isObject(_welcomeAD)) {
                            welcomeAD.push(_welcomeAD)
                        } else if (isArray(_welcomeAD)) {
                            welcomeAD.push(..._welcomeAD)
                        }
                        // 弹窗广告
                        if (isObject(_alertAD)) {
                            alertAD.push(_alertAD)
                        } else if (isArray(_alertAD)) {
                            alertAD.push(..._alertAD)
                        }
                        // 播放器顶部广告
                        if (isObject(_topAD)) {
                            topAD.push([_topAD])
                        } else if (isArray(_topAD)) {
                            topAD.push(_topAD)
                        }
                        // 播放器底部广告
                        if (isObject(_middleAD)) {
                            middleAD.push([_middleAD])
                        } else if (isArray(_middleAD)) {
                            middleAD.push(_middleAD)
                        }
                    }
                    // 视频广告
                    if (isObject(video_ad)) {
                        let {
                            welcome: _welcomeAD,
                            alert: _alertAD,
                            top: _topAD,
                            middle: _middleAD
                        } = video_ad
                        // 开屏广告
                        if (isObject(_welcomeAD)) {
                            welcomeAD.push(_welcomeAD)
                        } else if (isArray(_welcomeAD)) {
                            welcomeAD.push(..._welcomeAD)
                        }
                        // 弹窗广告
                        if (isObject(_alertAD)) {
                            alertAD.push(_alertAD)
                        } else if (isArray(_alertAD)) {
                            alertAD.push(..._alertAD)
                        }
                        // 播放器顶部广告
                        if (isObject(_topAD)) {
                            topAD.push([_topAD])
                        } else if (isArray(_topAD)) {
                            topAD.push(_topAD)
                        }
                        // 播放器底部广告
                        if (isObject(_middleAD)) {
                            middleAD.push([_middleAD])
                        } else if (isArray(_middleAD)) {
                            middleAD.push(_middleAD)
                        }
                    }
                    // 播放器广告
                    if (isArray(video_player_ad)) {
                        for (let i = 0, l = video_player_ad.length; i < l; i++) {
                            let obj = video_player_ad[i]
                            // 与小程序保持一致 第一个为片头广告 第二个为片尾广告
                            if (i == 0 || i == 1) {
                                playerAD.push({
                                    location: i == 0 ? 1 : 3, //广告位置 1片头广告 2片尾广告
                                    adtype: Number(obj.ad_resource), //广告类型 1图片广告 2视频广告
                                    matterurl: obj.matterurl,  //广告资源url
                                    addrurl: obj.addrurl, //广告点击跳转链接
                                    timesize: Number(obj.timesize) || 5, //广告时长
                                    skipenabled: false, //是否显示跳过按钮
                                })
                            }
                            // 根据返回内容添加
                            // playerAD.push({
                            //     location: obj.ad_type, //广告位置 1片头广告 2片尾广告
                            //     adtype: obj.ad_resource, //广告类型 1图片广告 2视频广告
                            //     matterurl: obj.matterurl,  //广告资源url
                            //     addrurl: obj.addrurl, //广告点击跳转链接
                            //     timesize: obj.timesize || 5, //广告时长
                            //     skipenabled: false, //是否显示跳过按钮
                            // })
                        }
                    } else if (isObject(video_player_ad)) {
                        playerAD.push({
                            location: Number(video_player_ad.ad_type), //广告位置 1片头广告 2片尾广告
                            adtype: Number(video_player_ad.ad_resource), //广告类型 1图片广告 2视频广告
                            matterurl: video_player_ad.matterurl,  //广告资源url
                            addrurl: video_player_ad.addrurl, //广告点击跳转链接
                            timesize: Number(video_player_ad.timesize) || 5, //广告时长
                            skipenabled: false, //是否显示跳过按钮
                        })
                    }
                    if (welcomeAD.length) {
                        // videoIsAutoplay = false
                        this.welcomeAD = welcomeAD
                    }
                    if (alertAD.length) {
                        // videoIsAutoplay = false
                        this.alertAD = alertAD
                    }
                    if (topAD.length) {
                        this.topAD = topAD
                    }
                    if (middleAD.length) {
                        this.middleAD = middleAD
                    }
                    if (playerAD.length) {
                        this.playerAD = playerAD
                    }
                }

                this.loading = false
            })
        },
        // 关键词分割
        strToArr(str) {
            return str.split('|')
        },
        // 开屏广告加载完成事件
        handleWelcomeADLoad() {
            let { welcomeADCountDown } = this
            let s = parseInt(welcomeADCountDown)
            this.welcomeAdTimer = setTimeout(() => {
                --s;
                this.welcomeADCountDown = s + '秒后关闭'
                clearTimeout(this.welcomeAdTimer);
                if (s > 0) {
                    this.handleWelcomeADLoad()
                } else {
                    this.handleCloseWelcomeAD()
                }
            }, 1000)
        },
        // 关闭开屏广告事件
        handleCloseWelcomeAD() {
            clearTimeout(this.welcomeAdTimer || 0);
            let { welcomeAD, alertAD, videoPlayer } = this
            welcomeAD.shift();
            this.welcomeAD = welcomeAD
            this.welcomeADCountDown = '5秒后关闭'
        },
        // 弹窗广告关闭事件
        handleCloseAlertAD() {
            let { alertAD, videoPlayer } = this
            alertAD.shift();
            this.alertAD = alertAD
        },
        // 关注公众号弹框
        closePopup() {
            this.isNeedAttentionAccounts = false
        },
        // 显示视频权限模态框
        handleVideoAuthModel() {
            // 视频信息 
            let { videoDetailList, videoAuth } = this
            // 2登记观看 与小程序保持一致 不显示
            // console.log(videoAuth.type)
            if (!(this.userInfo || {}).user_id) {
                if (videoAuth.type != 0 && videoAuth.type != 3 && videoAuth.type != 8) {
                    window.location.href = this.mini_url + "unified/login?return_url=" + encodeURIComponent(window.location.href);
                    return false
                }
            }
            // console.log(this.userInfo)
            if (videoAuth.type == 1) {
                window.location.href = this.mini_url + "unified/login?return_url=" + encodeURIComponent(window.location.href);
                // window.open()
                // wx.navigateTo({
                //     url: '/pages/res-register/res-register?type=4&type_id=' + this.videoId
                // })
            } else if (videoAuth.type == 6 && videoDetailList.meeting_id) {
                // 6 参会代表(参会注册观看)
                videoAuthJump(videoAuth.type, videoDetailList.meeting_id)
            } else if (videoAuth.type == 4) {
                // 4会员观看
                //  暂时关闭会员入口
                return false;
                videoAuthJump(4)
            } else if (videoAuth.type == 3 || videoAuth.type == 8) {
                // 3 密码观看 8 邀请码观看
                this.showAlert = true
            } else {
                // 5 付费观看 7参会缴费(接口返回的为会员权限type 4) 9定制观看 10平台会员 12指定注册类型观看
                if (!videoAuth.code_url) {
                    return false
                }
                videoAuthJump(videoAuth.type, videoAuth.code_url)
            }
        },
    },
    created() {
        this.pageInit();
        this.getRelatedVideo();
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.video-play {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;

    .video-outer-box {
        width: 750px;
        height: 414px;
        position: relative;
        // 视频广告默认倒计时隐藏
        // /deep/.pv-as-time-wrap{
        //     display: none;
        // }

        .welcome-ad-box {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000000;
            z-index: 3;
            .welcome-ad-img {
                height: 100%;
                max-width: 750px;
            }
            .count-down-text {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }

        .alert-ad-box {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.65);
            z-index: 2;

            .popup-content {
                img {
                    height: 300px;
                }
            }

            .alert-ad-img {
                width: 235px;
            }

            .close-btn-box {
                width: 28px;
                height: 28px;
                position: absolute;
                right: 20px;
                top: 10px;
                border-radius: 50%;
                background: rgba(128, 128, 128, 0.85);
                cursor: pointer;

                .close-btn-icon {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .alert-tad-box {
            width: 100%;
            position: absolute;
            height: 140px;
            top: 0;
            z-index: 2;

            img {
                height: 140px;
                width: auto !important;
            }
        }

        .alert-middlead-box {
            width: 100%;
            position: absolute;
            height: 50px;
            bottom: 0;
            z-index: 2;

            img {
                height: 50px;
                width: auto !important;
            }
        }

        .no-vid-box {
            width: 100%;
            height: 100%;
            background: #000000;
            cursor: pointer;

            .no-vid-img {
                height: 100%;
                width: auto;
                max-width: 750px;
            }

            .video-auth-box {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);

                .med-bofang {
                    font-size: 30px;
                    color: #ffffff;
                }
            }
        }
    }

    .video {
        width: calc(66.66% - 30px);
        // width: 770px;
        height: 414px;

        .pv-video-player {
            width: 100%;
            height: 414px;
        }
    }
}

.video-detail {
    width: 33.33%;
    height: 414px;
    .cursor {
        cursor: pointer;
    }

    .video-content-name {
        height: 100px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(30, 81, 201, 0.15);
        border-radius: 10px;

        p {
            margin-bottom: 0;
        }

        .isattention-btn {
            width: 64px;
            height: 34px;
            background: #eeeeee;
            border-radius: 5px;
        }

        .attention-btn {
            width: 64px;
            height: 34px;
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            border-radius: 5px;

            .med-jiahao::before {
                font-size: 10px;
                color: #ffffff;
            }
        }

        .img {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            object-fit: cover;
            object-position: top;
        }

        .line {
            width: 1px;
            height: 40px;
            background: #f5f5f5;
        }
    }

    .content-desc {
        overflow-y: scroll;
        height: 130px;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .content-subject {
        width: 100%;
        height: 28px;
        flex-wrap: wrap;
        overflow: hidden;

        &-item {
            padding: 4px 14px;
            background: linear-gradient(to right, #f5bf58, #f2873b);
            border-radius: 5px;
            box-shadow: 0 1px 2px 0 rgba(255, 164, 93, 0.15);
        }
    }

    .page-view {
        height: 18px;

        .med {
            font-size: 14px;
        }

        .med-dianzan::before,
        .med-shoucang1::before {
            color: #f5bf58;
        }
    }
}

.video-other-left {
    width: 743px;
}

.video-other,.video-detail {
    /deep/.ant-tabs-bar {
        border-bottom: 0;
        border-top: 1px solid #d8d8d8;
    }

    /deep/.ant-tabs-nav {
        float: left;
    }

    /deep/.ant-tabs-ink-bar {
        bottom: 0;
        top: 0;
        background: #1e51c9;
    }

    /deep/.ant-tabs-tab {
        font-size: 20px;
        color: #333333;
        padding: 10px 0 0;
    }

    /deep/.ant-tabs-tab-active {
        color: #1e51c9;
    }

    .img {
        width: 66px;
        height: 15px;
        display: block;
    }

    .dis-block {
        display: block;
        cursor: pointer;
    }

    .bb {
        border-bottom: 1px dashed #d8d8d8;
    }

    .list-items {
        min-height: 200px;
    }
}
@media screen and (max-width: 768px) {
    .video-wrapper {
        .container {
            display: block;
            padding: 0 10px;
        }
        .mobile-bread {
            display: none;
        }
        .video-outer-box {
            width: calc(100% + 20px);
            margin-left: -10px;
            height: auto;
        }
        .video-detail {
            width: 100%;
            height: auto;
            & > div {
                margin-top: 10px;
            }
            /deep/.ant-tabs-tab {
                font-size: 15px;
                margin:0;
                &.ant-tabs-tab-active {
                    font-weight: bold;
                }
            }
            /deep/.ant-tabs-nav {
                width: 100%;
                > div {
                    display: flex;
                    justify-content: space-around;
                }
            }
            /deep/.ant-tabs-ink-bar {
                display: none !important;
            }
        }
        .about-video {
            padding: 0 !important;
            margin-top: 20px;
        }
        .video-other {
            width: 100%;
            /deep/.ant-tabs-tab {
                font-size: 15px;
                margin:0;
                &.ant-tabs-tab-active {
                    font-weight: bold;
                }
            }
            /deep/.ant-tabs-nav {
                width: 100%;
                > div {
                    display: flex;
                    justify-content: space-around;
                }
            }
            /deep/.ant-tabs-ink-bar {
                display: none !important;
            }
            .comment-box {
                padding: 0 10px;
            }
        }
    }
    .about-meeting {
        display: none;
    }
}
</style>